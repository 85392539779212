<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          solo-inverted
          clearable
          box
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        class="px-1 pb-1"
        item-key="entry"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <tr>
            <td class="text-xs-left">
              {{ props.item.entry }}
            </td>
            <td
              v-if="props.item.action === 'purchase' && props.item.pin !== 'BPin'"
              class="text-xs-left"
            >
              Purchase from:
            </td>
            <td
              v-else-if="props.item.action === 'purchase' && props.item.pin === 'BPin'"
              class="text-xs-left"
            >
              Stock:
            </td>
            <td
              v-else-if="props.item.action === 'transfer'"
              class="text-xs-left"
            >
              Transfer to:
            </td>
            <td
              v-else-if="props.item.action === 'subscription'"
              class="text-xs-left"
            >
              Subscription:
            </td>
            <td
              v-else-if="props.item.action === 'receive'"
              class="text-xs-left"
            >
              Receive from:
            </td>
            <td
              v-else-if="props.item.action === 'appoint'"
              class="text-xs-left"
            >
              Dealer appointment of:
            </td>
            <td
              v-else
              class="text-xs-left"
            >
              {{ props.item.action }}
            </td>
            <td class="text-xs-left">
              {{ props.item.entity }}
            </td>
            <td class="text-xs-left">
              {{ props.item.pin }}
            </td>
            <td
              v-if="props.item.action === 'appoint' || props.item.action === 'transfer'"
              class="text-xs-center"
            >
              <span class="warning--text">{{ props.item.quantity }}</span>
            </td>
            <td
              v-else
              class="text-xs-center"
            >
              <span class="success--text">{{ props.item.quantity }}</span>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <template v-if="Dealer">
      <br>
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            solo-inverted
            clearable
            box
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers2"
          :items="recruits"
          :loading="loading"
          :pagination.sync="pagination"
          :search="search"
          class="px-1 pb-1"
          item-key="entry"
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr>
              <td class="text-xs-left">
                {{ props.item.entry }}
              </td>
              <td
                v-if="props.item.action === 'appoint'"
                class="text-xs-left"
              >
                Dealer appointment of:
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                {{ props.item.action }}
              </td>
              <td class="text-xs-left">
                {{ props.item.entity }}
              </td>
              <td class="text-xs-left">
                {{ props.item.pin }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'
import security from '@/security'

const reportTitle = 'PIN Records'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Dealership', disabled: false, to: '/dealership_kits',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Date',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Entity',
          value: 'entity',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Pin',
          value: 'pin',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Quantity',
          value: 'quantity',
        },
      ],
      headers2: [
        {
          align: 'left',
          sortable: true,
          text: 'Date',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Entity',
          value: 'entity',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Pin',
          value: 'pin',
        },
      ],
      items: [
      ],
      recruits: [],
      loading: false,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'entry',
      },
      search: '',
      title: reportTitle,
    }
  },
  mounted: function () {
    this.getDealershipPinRecords()
  },
  computed: {
    Dealer: function () {
      return security.me.dealerId() === 'D000001'
    },
  },
  methods: {
    getDealershipPinRecords () {
      this.loading = true
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getDealershipPinRecords.php', params)
        .then(function (response) {
          this.items = response.data.items
          this.recruits = response.data.recruit
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
